/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 18:03:26 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-18 18:11:00
 */
<template>
  <div class="myTimetable">
    <h3 class="personal_title">我的课表</h3>
    <div class="cont">
      <div class="time">
        <!-- <span class="demonstration">默认</span> -->
        <el-date-picker
          v-model="dateTime"
          @change="dateTimeChange()"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="">
        <el-table border :data="tableData">
          <el-table-column
            align="center"
            prop="CourseName"
            label="课程名称"
          ></el-table-column>
          <el-table-column align="center" label="上课时间">
            <template slot-scope="scope">
              <div>
                {{ scope.row.OpenDate | dataYMD }} {{ scope.row.Start }}~{{
                  scope.row.End
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="上课地点">
            <template slot-scope="scope">
              <div>
                {{ scope.row.ClassRoomName
                }}{{ scope.row.IsLive == 1 ? "+线上直播" : "" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="Remark"
            label="上课内容"
          ></el-table-column>
          <el-table-column align="center" prop="IsSign" label="操作">
            <template slot-scope="scope">
              <div
                class="signIn"
                v-if="scope.row.IsSign == 0"
                @click="studentSign(scope.row)"
              >
                签到
              </div>
              <div class="signedIn" v-if="scope.row.IsSign == 1">已签到</div>
              <div
                class="checkTimes"
                v-if="scope.row.IsSign == 1"
                @click="signCount(scope.row)"
              >
                已签到{{ scope.row.CurrentStudentSignCount }}次
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length > 0"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <check-record-details
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      :row="row"
      :times="times"
      @close="getClose"
    ></check-record-details>
  </div>
</template>

<script>
import { studentQueryStudentCoursePlanList, studentSignByTable } from "@/api/personal";
import checkRecordDetails from "./components/checkRecordDetails";
export default {
  data () {
    return {
      dateTime: [],
      tableData: [],
      page: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      row: {},
      times: {}
    };
  },
  components: {
    checkRecordDetails
  },
  created () { },
  mounted () {
    this.dateWeek();
  },
  methods: {
    getClose () {
      this.dialogVisible = false;
    },
    signCount (row) {
      this.row = row
      this.times = {
        startTime: this.startTime,
        endTime: this.endTime,
      }
      this.dialogVisible = true
    },
    // 签到
    async studentSign (item) {
      if (
        new Date().getTime() > new Date(item.EndTime.replace(/-/g, '/')).getTime() ||
        new Date().getTime() <
        new Date(item.StartTime.replace(/-/g, '/')).getTime() - 30 * 60 * 1000
      ) {
        this.$message.error("当前时段不能签到");
        return false;
      }
      let data = {
        StudentId: this.studentId,
        CourseId: item.CourseId,
        PlanId: item.Id,
      };
      // console.log(data);
      const res = await studentSignByTable(data);
      if (res.success === true) {
        this.$message.success("签到成功");
        item.IsSign = 1;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    dateWeek () {
      var now = new Date();
      var weekFirstDay = new Date(now - (now.getDay() - 1) * 86400000);
      var firstMonth = Number(weekFirstDay.getMonth()) + 1;
      var weekLastDay = new Date((weekFirstDay / 1000 + 6 * 86400) * 1000);
      var lastMonth = Number(weekLastDay.getMonth()) + 1;
      this.startTime =
        weekFirstDay.getFullYear() +
        "-" +
        firstMonth.toString().padStart(2, 0) +
        "-" +
        weekFirstDay.getDate().toString().padStart(2, 0) +
        " 00:00:00";
      this.endTime =
        weekLastDay.getFullYear() +
        "-" +
        lastMonth.toString().padStart(2, 0) +
        "-" +
        weekLastDay.getDate().toString().padStart(2, 0) +
        " 00:00:00";
      // this.startTime = "2020-01-01 00:00:00";
      // this.endTime = "2022-01-01 00:00:00";
      this.dateTime = [this.startTime, this.endTime];
      this.init();
    },
    //选择时间
    dateTimeChange () {
      if (this.dateTime.length == 2) {
        this.startTime = this.common.dataYMDhms(this.dateTime[0]);
        this.endTime = this.common.dataYMDhms(this.dateTime[1]);
        this.init();
      }
    },
    async init () {
      let parm = {
        startTime: this.startTime,
        endTime: this.endTime,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await studentQueryStudentCoursePlanList(parm);
      if (res.success === true) {
        this.tableData = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.myTimetable {
  .cont {
    padding: 30px;
    .time {
      margin-bottom: 30px;
    }
  }
  .signIn {
    width: 62px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    background-color: #558af9;
    border-radius: 30px;
    margin: 0 auto;
  }
  .signedIn {
    width: 62px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #999999;
    background-color: #ececef;
    border-radius: 30px;
    margin: 0 auto;
  }
  .checkTimes {
    min-width: 38px;
    height: 20px;
    line-height: 20px;
    background: #2dd6b1;
    padding: 0 10px;
    color: #ffffff;
    font-size: 12px;
    border-radius: 0px 0px 0px 6px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>