/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:29:45 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-16 10:55:53
 */
<template>
  <div class="see">
    <el-dialog
      title="签到记录"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="see_cont">
        <el-table
          :data="list"
          border
          header-cell-class-name="hader_th"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" width="75">
          </el-table-column>
          <el-table-column prop="Remark" label="内容"> </el-table-column>
          <el-table-column label="时间">
            <template slot-scope="scope">
              {{ scope.row.StartTime | dataYMD }} <br />{{
                scope.row.StartTime | hms
              }}~{{ scope.row.EndTime | hms }}
            </template>
          </el-table-column>
          <el-table-column label="打卡方式" width="150">
            <template slot-scope="scope">
              <!-- 签到状态（0直播签到、1手动签到、2老师签到、null未签到） -->
              {{
                scope.row.SignStatus == 0
                  ? "直播签到"
                  : scope.row.SignStatus == 1
                  ? "手动签到"
                  : scope.row.SignStatus == 2
                  ? "老师签到"
                  : "未签到"
              }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { studentQueryStudentSignDetail } from "@/api/personal";
export default {
  props: ["dialogVisible", "row", "times"],
  data () {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      user: {}
    };
  },
  created () { },
  mounted () {
    this.user = JSON.parse(this.$store.state.userInfo);
    this.init();
  },
  methods: {
    async init () {
      let parm = {
        studentId: this.user.Id,
        CourseId: this.row.CourseId,
        StartTime: this.times.startTime,
        EndTime: this.times.endTime,
      };
      const res = await studentQueryStudentSignDetail(parm);
      if (res.success === true) {
        this.list = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleClose () {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.see {
  /deep/.el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0 0;
      background-color: #1288f4;
      // padding: 0;
      .el-dialog__title {
        color: #ffffff;
        // height: 60px;
        // line-height: 60px;
        // padding: 0 30px;
      }
    }
    .see_cont {
      line-height: 40px;
      .el-row {
        margin-bottom: 10px;
        font-size: 14px;
        .comment {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>